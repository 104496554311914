import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddProductToCartMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
}>;


export type AddProductToCartMutation = { __typename?: 'Mutation', addProductToCart: boolean };


export const AddProductToCartDocument = gql`
    mutation addProductToCart($productId: ID!) {
  addProductToCart(productId: $productId)
}
    `;
export type AddProductToCartMutationFn = ApolloReactCommon.MutationFunction<AddProductToCartMutation, AddProductToCartMutationVariables>;
export type AddProductToCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddProductToCartMutation, AddProductToCartMutationVariables>, 'mutation'>;

    export const AddProductToCartComponent = (props: AddProductToCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddProductToCartMutation, AddProductToCartMutationVariables> mutation={AddProductToCartDocument} {...props} />
    );
    

/**
 * __useAddProductToCartMutation__
 *
 * To run a mutation, you first call `useAddProductToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToCartMutation, { data, loading, error }] = useAddProductToCartMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useAddProductToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProductToCartMutation, AddProductToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddProductToCartMutation, AddProductToCartMutationVariables>(AddProductToCartDocument, options);
      }
export type AddProductToCartMutationHookResult = ReturnType<typeof useAddProductToCartMutation>;
export type AddProductToCartMutationResult = ApolloReactCommon.MutationResult<AddProductToCartMutation>;
export type AddProductToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProductToCartMutation, AddProductToCartMutationVariables>;