import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RemoveProductFromWishlistMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
}>;


export type RemoveProductFromWishlistMutation = { __typename?: 'Mutation', removeProductFromWishlist: boolean };


export const RemoveProductFromWishlistDocument = gql`
    mutation removeProductFromWishlist($productId: ID!) {
  removeProductFromWishlist(productId: $productId)
}
    `;
export type RemoveProductFromWishlistMutationFn = ApolloReactCommon.MutationFunction<RemoveProductFromWishlistMutation, RemoveProductFromWishlistMutationVariables>;
export type RemoveProductFromWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveProductFromWishlistMutation, RemoveProductFromWishlistMutationVariables>, 'mutation'>;

    export const RemoveProductFromWishlistComponent = (props: RemoveProductFromWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveProductFromWishlistMutation, RemoveProductFromWishlistMutationVariables> mutation={RemoveProductFromWishlistDocument} {...props} />
    );
    

/**
 * __useRemoveProductFromWishlistMutation__
 *
 * To run a mutation, you first call `useRemoveProductFromWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductFromWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductFromWishlistMutation, { data, loading, error }] = useRemoveProductFromWishlistMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveProductFromWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProductFromWishlistMutation, RemoveProductFromWishlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveProductFromWishlistMutation, RemoveProductFromWishlistMutationVariables>(RemoveProductFromWishlistDocument, options);
      }
export type RemoveProductFromWishlistMutationHookResult = ReturnType<typeof useRemoveProductFromWishlistMutation>;
export type RemoveProductFromWishlistMutationResult = ApolloReactCommon.MutationResult<RemoveProductFromWishlistMutation>;
export type RemoveProductFromWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProductFromWishlistMutation, RemoveProductFromWishlistMutationVariables>;