import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ProductsQueryVariables = Types.Exact<{
  productFilter: Types.ProductFilter;
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
}>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsResponse', products: Array<{ __typename?: 'MinifiedProduct', productId: string, title: string, price: number, thumbnailUrl: string, imageUrls: Array<string>, isOutOfStock: boolean }>, pageInfo: { __typename?: 'PageInfo', totalPages: number, totalElements: number, pageSize: number, currentPage: number } } };


export const ProductsDocument = gql`
    query products($productFilter: ProductFilter!, $pageNumber: Int!, $pageSize: Int!) {
  products(
    productFilter: $productFilter
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    products {
      productId
      title
      price
      thumbnailUrl
      imageUrls
      isOutOfStock
    }
    pageInfo {
      totalPages
      totalElements
      pageSize
      currentPage
    }
  }
}
    `;
export type ProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductsQuery, ProductsQueryVariables>, 'query'> & ({ variables: ProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductsComponent = (props: ProductsComponentProps) => (
      <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables> query={ProductsDocument} {...props} />
    );
    

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      productFilter: // value for 'productFilter'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables> & ({ variables: ProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export function useProductsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsSuspenseQueryHookResult = ReturnType<typeof useProductsSuspenseQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;