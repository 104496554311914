import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserCartQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCartQuery = { __typename?: 'Query', userCart: Array<{ __typename?: 'CartItem', productId: string, title: string, price: number, imageUrl: string, quantity: number }> };


export const UserCartDocument = gql`
    query userCart {
  userCart {
    productId
    title
    price
    imageUrl
    quantity
  }
}
    `;
export type UserCartComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserCartQuery, UserCartQueryVariables>, 'query'>;

    export const UserCartComponent = (props: UserCartComponentProps) => (
      <ApolloReactComponents.Query<UserCartQuery, UserCartQueryVariables> query={UserCartDocument} {...props} />
    );
    

/**
 * __useUserCartQuery__
 *
 * To run a query within a React component, call `useUserCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCartQuery, UserCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserCartQuery, UserCartQueryVariables>(UserCartDocument, options);
      }
export function useUserCartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCartQuery, UserCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserCartQuery, UserCartQueryVariables>(UserCartDocument, options);
        }
export function useUserCartSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserCartQuery, UserCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserCartQuery, UserCartQueryVariables>(UserCartDocument, options);
        }
export type UserCartQueryHookResult = ReturnType<typeof useUserCartQuery>;
export type UserCartLazyQueryHookResult = ReturnType<typeof useUserCartLazyQuery>;
export type UserCartSuspenseQueryHookResult = ReturnType<typeof useUserCartSuspenseQuery>;
export type UserCartQueryResult = ApolloReactCommon.QueryResult<UserCartQuery, UserCartQueryVariables>;