import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserAddressesMinifiedQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserAddressesMinifiedQuery = { __typename?: 'Query', userAddressesMinified: Array<{ __typename?: 'MinifiedShippingAddress', addressId: string, name: string, isDefault: boolean }> };


export const UserAddressesMinifiedDocument = gql`
    query userAddressesMinified {
  userAddressesMinified {
    addressId
    name
    isDefault
  }
}
    `;
export type UserAddressesMinifiedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>, 'query'>;

    export const UserAddressesMinifiedComponent = (props: UserAddressesMinifiedComponentProps) => (
      <ApolloReactComponents.Query<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables> query={UserAddressesMinifiedDocument} {...props} />
    );
    

/**
 * __useUserAddressesMinifiedQuery__
 *
 * To run a query within a React component, call `useUserAddressesMinifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAddressesMinifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAddressesMinifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAddressesMinifiedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>(UserAddressesMinifiedDocument, options);
      }
export function useUserAddressesMinifiedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>(UserAddressesMinifiedDocument, options);
        }
export function useUserAddressesMinifiedSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>(UserAddressesMinifiedDocument, options);
        }
export type UserAddressesMinifiedQueryHookResult = ReturnType<typeof useUserAddressesMinifiedQuery>;
export type UserAddressesMinifiedLazyQueryHookResult = ReturnType<typeof useUserAddressesMinifiedLazyQuery>;
export type UserAddressesMinifiedSuspenseQueryHookResult = ReturnType<typeof useUserAddressesMinifiedSuspenseQuery>;
export type UserAddressesMinifiedQueryResult = ApolloReactCommon.QueryResult<UserAddressesMinifiedQuery, UserAddressesMinifiedQueryVariables>;