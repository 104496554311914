import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ShippingAddressQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;


export type ShippingAddressQuery = { __typename?: 'Query', shippingAddress: { __typename?: 'ShippingAddress', addressId: string, name: string, addressLine1: string, addressLine2?: string | null, city: string, state: string, country: string, pincode: string, addressType: Types.AddressType, isDefault: boolean } };


export const ShippingAddressDocument = gql`
    query shippingAddress($addressId: ID!) {
  shippingAddress(addressId: $addressId) {
    addressId
    name
    addressLine1
    addressLine2
    city
    state
    country
    pincode
    addressType
    isDefault
  }
}
    `;
export type ShippingAddressComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ShippingAddressQuery, ShippingAddressQueryVariables>, 'query'> & ({ variables: ShippingAddressQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ShippingAddressComponent = (props: ShippingAddressComponentProps) => (
      <ApolloReactComponents.Query<ShippingAddressQuery, ShippingAddressQueryVariables> query={ShippingAddressDocument} {...props} />
    );
    

/**
 * __useShippingAddressQuery__
 *
 * To run a query within a React component, call `useShippingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingAddressQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useShippingAddressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ShippingAddressQuery, ShippingAddressQueryVariables> & ({ variables: ShippingAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ShippingAddressQuery, ShippingAddressQueryVariables>(ShippingAddressDocument, options);
      }
export function useShippingAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShippingAddressQuery, ShippingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ShippingAddressQuery, ShippingAddressQueryVariables>(ShippingAddressDocument, options);
        }
export function useShippingAddressSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ShippingAddressQuery, ShippingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<ShippingAddressQuery, ShippingAddressQueryVariables>(ShippingAddressDocument, options);
        }
export type ShippingAddressQueryHookResult = ReturnType<typeof useShippingAddressQuery>;
export type ShippingAddressLazyQueryHookResult = ReturnType<typeof useShippingAddressLazyQuery>;
export type ShippingAddressSuspenseQueryHookResult = ReturnType<typeof useShippingAddressSuspenseQuery>;
export type ShippingAddressQueryResult = ApolloReactCommon.QueryResult<ShippingAddressQuery, ShippingAddressQueryVariables>;