import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ShippingAddressesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShippingAddressesQuery = { __typename?: 'Query', shippingAddresses: Array<{ __typename?: 'ShippingAddress', addressId: string, name: string, addressLine1: string, addressLine2?: string | null, city: string, state: string, country: string, pincode: string, addressType: Types.AddressType, isDefault: boolean }> };


export const ShippingAddressesDocument = gql`
    query shippingAddresses {
  shippingAddresses {
    addressId
    name
    addressLine1
    addressLine2
    city
    state
    country
    pincode
    addressType
    isDefault
  }
}
    `;
export type ShippingAddressesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ShippingAddressesQuery, ShippingAddressesQueryVariables>, 'query'>;

    export const ShippingAddressesComponent = (props: ShippingAddressesComponentProps) => (
      <ApolloReactComponents.Query<ShippingAddressesQuery, ShippingAddressesQueryVariables> query={ShippingAddressesDocument} {...props} />
    );
    

/**
 * __useShippingAddressesQuery__
 *
 * To run a query within a React component, call `useShippingAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useShippingAddressesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShippingAddressesQuery, ShippingAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ShippingAddressesQuery, ShippingAddressesQueryVariables>(ShippingAddressesDocument, options);
      }
export function useShippingAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShippingAddressesQuery, ShippingAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ShippingAddressesQuery, ShippingAddressesQueryVariables>(ShippingAddressesDocument, options);
        }
export function useShippingAddressesSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ShippingAddressesQuery, ShippingAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<ShippingAddressesQuery, ShippingAddressesQueryVariables>(ShippingAddressesDocument, options);
        }
export type ShippingAddressesQueryHookResult = ReturnType<typeof useShippingAddressesQuery>;
export type ShippingAddressesLazyQueryHookResult = ReturnType<typeof useShippingAddressesLazyQuery>;
export type ShippingAddressesSuspenseQueryHookResult = ReturnType<typeof useShippingAddressesSuspenseQuery>;
export type ShippingAddressesQueryResult = ApolloReactCommon.QueryResult<ShippingAddressesQuery, ShippingAddressesQueryVariables>;