import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateShippingAddressMutationVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
  input: Types.AddUpdateShippingAddressInput;
}>;


export type UpdateShippingAddressMutation = { __typename?: 'Mutation', updateShippingAddress: boolean };


export const UpdateShippingAddressDocument = gql`
    mutation updateShippingAddress($addressId: ID!, $input: AddUpdateShippingAddressInput!) {
  updateShippingAddress(
    addressId: $addressId
    addUpdateShippingAddressInput: $input
  )
}
    `;
export type UpdateShippingAddressMutationFn = ApolloReactCommon.MutationFunction<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables>;
export type UpdateShippingAddressComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables>, 'mutation'>;

    export const UpdateShippingAddressComponent = (props: UpdateShippingAddressComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables> mutation={UpdateShippingAddressDocument} {...props} />
    );
    

/**
 * __useUpdateShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingAddressMutation, { data, loading, error }] = useUpdateShippingAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShippingAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables>(UpdateShippingAddressDocument, options);
      }
export type UpdateShippingAddressMutationHookResult = ReturnType<typeof useUpdateShippingAddressMutation>;
export type UpdateShippingAddressMutationResult = ApolloReactCommon.MutationResult<UpdateShippingAddressMutation>;
export type UpdateShippingAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables>;