import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RemoveShippingAddressMutationVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;


export type RemoveShippingAddressMutation = { __typename?: 'Mutation', removeShippingAddress: boolean };


export const RemoveShippingAddressDocument = gql`
    mutation removeShippingAddress($addressId: ID!) {
  removeShippingAddress(addressId: $addressId)
}
    `;
export type RemoveShippingAddressMutationFn = ApolloReactCommon.MutationFunction<RemoveShippingAddressMutation, RemoveShippingAddressMutationVariables>;
export type RemoveShippingAddressComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveShippingAddressMutation, RemoveShippingAddressMutationVariables>, 'mutation'>;

    export const RemoveShippingAddressComponent = (props: RemoveShippingAddressComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveShippingAddressMutation, RemoveShippingAddressMutationVariables> mutation={RemoveShippingAddressDocument} {...props} />
    );
    

/**
 * __useRemoveShippingAddressMutation__
 *
 * To run a mutation, you first call `useRemoveShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShippingAddressMutation, { data, loading, error }] = useRemoveShippingAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useRemoveShippingAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveShippingAddressMutation, RemoveShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveShippingAddressMutation, RemoveShippingAddressMutationVariables>(RemoveShippingAddressDocument, options);
      }
export type RemoveShippingAddressMutationHookResult = ReturnType<typeof useRemoveShippingAddressMutation>;
export type RemoveShippingAddressMutationResult = ApolloReactCommon.MutationResult<RemoveShippingAddressMutation>;
export type RemoveShippingAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveShippingAddressMutation, RemoveShippingAddressMutationVariables>;