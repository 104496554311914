import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type EmptyCartMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type EmptyCartMutation = { __typename?: 'Mutation', emptyCart: boolean };


export const EmptyCartDocument = gql`
    mutation emptyCart {
  emptyCart
}
    `;
export type EmptyCartMutationFn = ApolloReactCommon.MutationFunction<EmptyCartMutation, EmptyCartMutationVariables>;
export type EmptyCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EmptyCartMutation, EmptyCartMutationVariables>, 'mutation'>;

    export const EmptyCartComponent = (props: EmptyCartComponentProps) => (
      <ApolloReactComponents.Mutation<EmptyCartMutation, EmptyCartMutationVariables> mutation={EmptyCartDocument} {...props} />
    );
    

/**
 * __useEmptyCartMutation__
 *
 * To run a mutation, you first call `useEmptyCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyCartMutation, { data, loading, error }] = useEmptyCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmptyCartMutation, EmptyCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EmptyCartMutation, EmptyCartMutationVariables>(EmptyCartDocument, options);
      }
export type EmptyCartMutationHookResult = ReturnType<typeof useEmptyCartMutation>;
export type EmptyCartMutationResult = ApolloReactCommon.MutationResult<EmptyCartMutation>;
export type EmptyCartMutationOptions = ApolloReactCommon.BaseMutationOptions<EmptyCartMutation, EmptyCartMutationVariables>;