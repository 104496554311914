import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PlaceOrderMutationVariables = Types.Exact<{
  placeOrderInput: Types.PlaceOrderInput;
}>;


export type PlaceOrderMutation = { __typename?: 'Mutation', placeOrder: boolean };


export const PlaceOrderDocument = gql`
    mutation placeOrder($placeOrderInput: PlaceOrderInput!) {
  placeOrder(placeOrderInput: $placeOrderInput)
}
    `;
export type PlaceOrderMutationFn = ApolloReactCommon.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;
export type PlaceOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PlaceOrderMutation, PlaceOrderMutationVariables>, 'mutation'>;

    export const PlaceOrderComponent = (props: PlaceOrderComponentProps) => (
      <ApolloReactComponents.Mutation<PlaceOrderMutation, PlaceOrderMutationVariables> mutation={PlaceOrderDocument} {...props} />
    );
    

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      placeOrderInput: // value for 'placeOrderInput'
 *   },
 * });
 */
export function usePlaceOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, options);
      }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = ApolloReactCommon.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;