import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserWishlistOperationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserWishlistOperationQuery = { __typename?: 'Query', userWishlist: Array<{ __typename?: 'MinifiedProduct', productId: string }> };


export const UserWishlistOperationDocument = gql`
    query userWishlistOperation {
  userWishlist {
    productId
  }
}
    `;
export type UserWishlistOperationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>, 'query'>;

    export const UserWishlistOperationComponent = (props: UserWishlistOperationComponentProps) => (
      <ApolloReactComponents.Query<UserWishlistOperationQuery, UserWishlistOperationQueryVariables> query={UserWishlistOperationDocument} {...props} />
    );
    

/**
 * __useUserWishlistOperationQuery__
 *
 * To run a query within a React component, call `useUserWishlistOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWishlistOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWishlistOperationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWishlistOperationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>(UserWishlistOperationDocument, options);
      }
export function useUserWishlistOperationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>(UserWishlistOperationDocument, options);
        }
export function useUserWishlistOperationSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>(UserWishlistOperationDocument, options);
        }
export type UserWishlistOperationQueryHookResult = ReturnType<typeof useUserWishlistOperationQuery>;
export type UserWishlistOperationLazyQueryHookResult = ReturnType<typeof useUserWishlistOperationLazyQuery>;
export type UserWishlistOperationSuspenseQueryHookResult = ReturnType<typeof useUserWishlistOperationSuspenseQuery>;
export type UserWishlistOperationQueryResult = ApolloReactCommon.QueryResult<UserWishlistOperationQuery, UserWishlistOperationQueryVariables>;