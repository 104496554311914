import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RemoveProductFromCartMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
  removeAll?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type RemoveProductFromCartMutation = { __typename?: 'Mutation', removeProductFromCart: boolean };


export const RemoveProductFromCartDocument = gql`
    mutation removeProductFromCart($productId: ID!, $removeAll: Boolean) {
  removeProductFromCart(productId: $productId, removeAll: $removeAll)
}
    `;
export type RemoveProductFromCartMutationFn = ApolloReactCommon.MutationFunction<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>;
export type RemoveProductFromCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>, 'mutation'>;

    export const RemoveProductFromCartComponent = (props: RemoveProductFromCartComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables> mutation={RemoveProductFromCartDocument} {...props} />
    );
    

/**
 * __useRemoveProductFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveProductFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductFromCartMutation, { data, loading, error }] = useRemoveProductFromCartMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      removeAll: // value for 'removeAll'
 *   },
 * });
 */
export function useRemoveProductFromCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>(RemoveProductFromCartDocument, options);
      }
export type RemoveProductFromCartMutationHookResult = ReturnType<typeof useRemoveProductFromCartMutation>;
export type RemoveProductFromCartMutationResult = ApolloReactCommon.MutationResult<RemoveProductFromCartMutation>;
export type RemoveProductFromCartMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>;