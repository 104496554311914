export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddCategoryInput = {
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  imageUrlType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: CategoryStatus;
};

export type AddProductInput = {
  categoryIds: Array<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  mediaFiles: Array<MediaFileInput>;
  price: Scalars['Float']['input'];
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  status: ProductStatus;
  thumbnailFileType: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AddUpdateShippingAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressType: AddressType;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  pincode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export enum AddressType {
  Home = 'HOME',
  Other = 'OTHER',
  Work = 'WORK'
}

export enum AuthByType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CartItem = {
  __typename?: 'CartItem';
  imageUrl: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  categoryId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: CategoryStatus;
};

export type CategoryFilter = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses: Array<CategoryStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum CategoryStatus {
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Published = 'PUBLISHED'
}

export type GeneratePresignedUrlsInput = {
  generateUrlFor: GenerateUrlFor;
  id: Scalars['ID']['input'];
  mediaFileTypes: Array<Scalars['String']['input']>;
  thumbnailFileType?: InputMaybe<Scalars['String']['input']>;
};

export enum GenerateUrlFor {
  Category = 'CATEGORY',
  Product = 'PRODUCT'
}

export type Media = {
  __typename?: 'Media';
  url: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type MediaFileInput = {
  fileType: Scalars['String']['input'];
  uuid: Scalars['ID']['input'];
};

export type MinifiedOrder = {
  __typename?: 'MinifiedOrder';
  orderDate: Scalars['String']['output'];
  orderId: Scalars['ID']['output'];
  orderStatus: OrderStatus;
  orderTotal: Scalars['Float']['output'];
};

export type MinifiedProduct = {
  __typename?: 'MinifiedProduct';
  id: Scalars['ID']['output'];
  imageUrls: Array<Scalars['String']['output']>;
  isOutOfStock: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  status: ProductStatus;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MinifiedReceivedOrder = {
  __typename?: 'MinifiedReceivedOrder';
  modeOfPayment: ModeOfPayment;
  name: Scalars['String']['output'];
  orderDate: Scalars['String']['output'];
  orderId: Scalars['ID']['output'];
  orderStatus: OrderStatus;
  orderTotal: Scalars['Float']['output'];
};

export type MinifiedShippingAddress = {
  __typename?: 'MinifiedShippingAddress';
  addressId: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum ModeOfPayment {
  Cod = 'COD',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  NetBanking = 'NET_BANKING',
  Upi = 'UPI'
}

export type Mutation = {
  __typename?: 'Mutation';
  addCategories: Scalars['Boolean']['output'];
  addCategory: Scalars['Boolean']['output'];
  addProduct: Scalars['Boolean']['output'];
  addProductToCart: Scalars['Boolean']['output'];
  addProductToWishlist: Scalars['Boolean']['output'];
  addProducts: Scalars['Boolean']['output'];
  addShippingAddress: Scalars['Boolean']['output'];
  contactUs: Scalars['Boolean']['output'];
  emptyCart: Scalars['Boolean']['output'];
  generatePresignedUrls: PresignedUrlsResponse;
  placeOrder: Scalars['Boolean']['output'];
  register: Scalars['ID']['output'];
  removeProductFromCart: Scalars['Boolean']['output'];
  removeProductFromWishlist: Scalars['Boolean']['output'];
  removeShippingAddress: Scalars['Boolean']['output'];
  requestOtp: Scalars['Boolean']['output'];
  updateCategory: Scalars['Boolean']['output'];
  updateOrderStatus: Scalars['Boolean']['output'];
  updateProduct: Scalars['Boolean']['output'];
  updateShippingAddress: Scalars['Boolean']['output'];
  verifyOtp: VerifyOtpResponse;
};


export type MutationAddCategoriesArgs = {
  addCategoriesInput: Array<AddCategoryInput>;
};


export type MutationAddCategoryArgs = {
  addCategoriesInput: AddCategoryInput;
};


export type MutationAddProductArgs = {
  addProductInput: AddProductInput;
};


export type MutationAddProductToCartArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationAddProductToWishlistArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationAddProductsArgs = {
  addProductsInput: Array<AddProductInput>;
};


export type MutationAddShippingAddressArgs = {
  addUpdateShippingAddressInput: AddUpdateShippingAddressInput;
};


export type MutationContactUsArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationGeneratePresignedUrlsArgs = {
  generatePresignedUrlsInput: GeneratePresignedUrlsInput;
};


export type MutationPlaceOrderArgs = {
  placeOrderInput: PlaceOrderInput;
};


export type MutationRegisterArgs = {
  registerUserInput: RegisterUserInput;
};


export type MutationRemoveProductFromCartArgs = {
  productId: Scalars['ID']['input'];
  removeAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRemoveProductFromWishlistArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationRemoveShippingAddressArgs = {
  addressId: Scalars['ID']['input'];
};


export type MutationRequestOtpArgs = {
  contactMethod: AuthByType;
  sendTo: Scalars['String']['input'];
};


export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['ID']['input'];
  updateCategoryInput: UpdateCategoryInput;
};


export type MutationUpdateOrderStatusArgs = {
  orderId: Scalars['ID']['input'];
  status: OrderStatus;
};


export type MutationUpdateProductArgs = {
  productId: Scalars['ID']['input'];
  updateProductInput: UpdateProductInput;
};


export type MutationUpdateShippingAddressArgs = {
  addUpdateShippingAddressInput: AddUpdateShippingAddressInput;
  addressId: Scalars['ID']['input'];
};


export type MutationVerifyOtpArgs = {
  contactMethod: AuthByType;
  otp: Scalars['String']['input'];
  sendTo: Scalars['String']['input'];
};

export type Order = {
  __typename?: 'Order';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  modeOfPayment: ModeOfPayment;
  name: Scalars['String']['output'];
  orderDate: Scalars['String']['output'];
  orderId: Scalars['ID']['output'];
  orderItems: Array<OrderItem>;
  orderStatus: OrderStatus;
  orderTotal: Scalars['Float']['output'];
  phone: Scalars['String']['output'];
  pincode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  imageUrl: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type OrderItemInput = {
  price: Scalars['Float']['input'];
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  Pending = 'PENDING',
  Shipped = 'SHIPPED'
}

export type OrdersResponse = {
  __typename?: 'OrdersResponse';
  orders: Array<MinifiedReceivedOrder>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PlaceOrderInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  modeOfPayment: ModeOfPayment;
  name: Scalars['String']['input'];
  orderItems: Array<OrderItemInput>;
  phoneNumber: Scalars['String']['input'];
  pincode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type PresignedUrlsResponse = {
  __typename?: 'PresignedUrlsResponse';
  mediaUrls: Array<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  categoryIds: Array<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isOutOfStock: Scalars['Boolean']['output'];
  medias: Array<Media>;
  price: Scalars['Float']['output'];
  productId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  status: ProductStatus;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ProductFilter = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses: Array<ProductStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductStatus {
  All = 'ALL',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Published = 'PUBLISHED'
}

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  pageInfo: PageInfo;
  products: Array<MinifiedProduct>;
};

export type Query = {
  __typename?: 'Query';
  categories: Array<Category>;
  category: Category;
  orderDetails: Order;
  orders: OrdersResponse;
  product: Product;
  products: ProductsResponse;
  shippingAddress: ShippingAddress;
  shippingAddresses: Array<ShippingAddress>;
  userAddressesMinified: Array<MinifiedShippingAddress>;
  userCart: Array<CartItem>;
  userDetail: User;
  userOrderHistory: Array<MinifiedOrder>;
  userWishlist: Array<MinifiedProduct>;
  users: Array<User>;
};


export type QueryCategoriesArgs = {
  categoryFilter: CategoryFilter;
};


export type QueryCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type QueryOrderDetailsArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryProductArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryProductsArgs = {
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  productFilter: ProductFilter;
};


export type QueryShippingAddressArgs = {
  addressId: Scalars['ID']['input'];
};

export type RegisterUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  userRole?: InputMaybe<UserRole>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  addressId: Scalars['ID']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressType: AddressType;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pincode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type UpdateCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  mediaFileType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CategoryStatus>;
};

export type UpdateProductInput = {
  categoryIds: Array<Scalars['ID']['input']>;
  deletedMediaFiles?: InputMaybe<Array<MediaFileInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  mediaFiles: Array<MediaFileInput>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProductStatus>;
  thumbnailFileType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type VerifyOtpResponse = {
  __typename?: 'VerifyOtpResponse';
  isVerified: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};
