import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserCartOperationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCartOperationQuery = { __typename?: 'Query', userCart: Array<{ __typename?: 'CartItem', productId: string, quantity: number }> };


export const UserCartOperationDocument = gql`
    query userCartOperation {
  userCart {
    productId
    quantity
  }
}
    `;
export type UserCartOperationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserCartOperationQuery, UserCartOperationQueryVariables>, 'query'>;

    export const UserCartOperationComponent = (props: UserCartOperationComponentProps) => (
      <ApolloReactComponents.Query<UserCartOperationQuery, UserCartOperationQueryVariables> query={UserCartOperationDocument} {...props} />
    );
    

/**
 * __useUserCartOperationQuery__
 *
 * To run a query within a React component, call `useUserCartOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCartOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCartOperationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCartOperationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCartOperationQuery, UserCartOperationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserCartOperationQuery, UserCartOperationQueryVariables>(UserCartOperationDocument, options);
      }
export function useUserCartOperationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCartOperationQuery, UserCartOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserCartOperationQuery, UserCartOperationQueryVariables>(UserCartOperationDocument, options);
        }
export function useUserCartOperationSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserCartOperationQuery, UserCartOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserCartOperationQuery, UserCartOperationQueryVariables>(UserCartOperationDocument, options);
        }
export type UserCartOperationQueryHookResult = ReturnType<typeof useUserCartOperationQuery>;
export type UserCartOperationLazyQueryHookResult = ReturnType<typeof useUserCartOperationLazyQuery>;
export type UserCartOperationSuspenseQueryHookResult = ReturnType<typeof useUserCartOperationSuspenseQuery>;
export type UserCartOperationQueryResult = ApolloReactCommon.QueryResult<UserCartOperationQuery, UserCartOperationQueryVariables>;