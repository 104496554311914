import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddProductToWishlistMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
}>;


export type AddProductToWishlistMutation = { __typename?: 'Mutation', addProductToWishlist: boolean };


export const AddProductToWishlistDocument = gql`
    mutation addProductToWishlist($productId: ID!) {
  addProductToWishlist(productId: $productId)
}
    `;
export type AddProductToWishlistMutationFn = ApolloReactCommon.MutationFunction<AddProductToWishlistMutation, AddProductToWishlistMutationVariables>;
export type AddProductToWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddProductToWishlistMutation, AddProductToWishlistMutationVariables>, 'mutation'>;

    export const AddProductToWishlistComponent = (props: AddProductToWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<AddProductToWishlistMutation, AddProductToWishlistMutationVariables> mutation={AddProductToWishlistDocument} {...props} />
    );
    

/**
 * __useAddProductToWishlistMutation__
 *
 * To run a mutation, you first call `useAddProductToWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToWishlistMutation, { data, loading, error }] = useAddProductToWishlistMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useAddProductToWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProductToWishlistMutation, AddProductToWishlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddProductToWishlistMutation, AddProductToWishlistMutationVariables>(AddProductToWishlistDocument, options);
      }
export type AddProductToWishlistMutationHookResult = ReturnType<typeof useAddProductToWishlistMutation>;
export type AddProductToWishlistMutationResult = ApolloReactCommon.MutationResult<AddProductToWishlistMutation>;
export type AddProductToWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProductToWishlistMutation, AddProductToWishlistMutationVariables>;