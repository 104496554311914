import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserWishlistQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserWishlistQuery = { __typename?: 'Query', userWishlist: Array<{ __typename?: 'MinifiedProduct', productId: string, title: string, imageUrls: Array<string>, thumbnailUrl: string, price: number, quantity: number, isOutOfStock: boolean }> };


export const UserWishlistDocument = gql`
    query userWishlist {
  userWishlist {
    productId
    title
    imageUrls
    thumbnailUrl
    price
    quantity
    isOutOfStock
  }
}
    `;
export type UserWishlistComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserWishlistQuery, UserWishlistQueryVariables>, 'query'>;

    export const UserWishlistComponent = (props: UserWishlistComponentProps) => (
      <ApolloReactComponents.Query<UserWishlistQuery, UserWishlistQueryVariables> query={UserWishlistDocument} {...props} />
    );
    

/**
 * __useUserWishlistQuery__
 *
 * To run a query within a React component, call `useUserWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWishlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWishlistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWishlistQuery, UserWishlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserWishlistQuery, UserWishlistQueryVariables>(UserWishlistDocument, options);
      }
export function useUserWishlistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWishlistQuery, UserWishlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserWishlistQuery, UserWishlistQueryVariables>(UserWishlistDocument, options);
        }
export function useUserWishlistSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserWishlistQuery, UserWishlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserWishlistQuery, UserWishlistQueryVariables>(UserWishlistDocument, options);
        }
export type UserWishlistQueryHookResult = ReturnType<typeof useUserWishlistQuery>;
export type UserWishlistLazyQueryHookResult = ReturnType<typeof useUserWishlistLazyQuery>;
export type UserWishlistSuspenseQueryHookResult = ReturnType<typeof useUserWishlistSuspenseQuery>;
export type UserWishlistQueryResult = ApolloReactCommon.QueryResult<UserWishlistQuery, UserWishlistQueryVariables>;