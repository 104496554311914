import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MinifiedProductDetailsQueryVariables = Types.Exact<{
  productFilter: Types.ProductFilter;
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
}>;


export type MinifiedProductDetailsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsResponse', products: Array<{ __typename?: 'MinifiedProduct', productId: string, quantity: number, isOutOfStock: boolean }> } };


export const MinifiedProductDetailsDocument = gql`
    query minifiedProductDetails($productFilter: ProductFilter!, $pageNumber: Int!, $pageSize: Int!) {
  products(
    productFilter: $productFilter
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    products {
      productId
      quantity
      isOutOfStock
    }
  }
}
    `;
export type MinifiedProductDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>, 'query'> & ({ variables: MinifiedProductDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MinifiedProductDetailsComponent = (props: MinifiedProductDetailsComponentProps) => (
      <ApolloReactComponents.Query<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables> query={MinifiedProductDetailsDocument} {...props} />
    );
    

/**
 * __useMinifiedProductDetailsQuery__
 *
 * To run a query within a React component, call `useMinifiedProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinifiedProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinifiedProductDetailsQuery({
 *   variables: {
 *      productFilter: // value for 'productFilter'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useMinifiedProductDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables> & ({ variables: MinifiedProductDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>(MinifiedProductDetailsDocument, options);
      }
export function useMinifiedProductDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>(MinifiedProductDetailsDocument, options);
        }
export function useMinifiedProductDetailsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>(MinifiedProductDetailsDocument, options);
        }
export type MinifiedProductDetailsQueryHookResult = ReturnType<typeof useMinifiedProductDetailsQuery>;
export type MinifiedProductDetailsLazyQueryHookResult = ReturnType<typeof useMinifiedProductDetailsLazyQuery>;
export type MinifiedProductDetailsSuspenseQueryHookResult = ReturnType<typeof useMinifiedProductDetailsSuspenseQuery>;
export type MinifiedProductDetailsQueryResult = ApolloReactCommon.QueryResult<MinifiedProductDetailsQuery, MinifiedProductDetailsQueryVariables>;