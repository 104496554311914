import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddShippingAddressMutationVariables = Types.Exact<{
  input: Types.AddUpdateShippingAddressInput;
}>;


export type AddShippingAddressMutation = { __typename?: 'Mutation', addShippingAddress: boolean };


export const AddShippingAddressDocument = gql`
    mutation addShippingAddress($input: AddUpdateShippingAddressInput!) {
  addShippingAddress(addUpdateShippingAddressInput: $input)
}
    `;
export type AddShippingAddressMutationFn = ApolloReactCommon.MutationFunction<AddShippingAddressMutation, AddShippingAddressMutationVariables>;
export type AddShippingAddressComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddShippingAddressMutation, AddShippingAddressMutationVariables>, 'mutation'>;

    export const AddShippingAddressComponent = (props: AddShippingAddressComponentProps) => (
      <ApolloReactComponents.Mutation<AddShippingAddressMutation, AddShippingAddressMutationVariables> mutation={AddShippingAddressDocument} {...props} />
    );
    

/**
 * __useAddShippingAddressMutation__
 *
 * To run a mutation, you first call `useAddShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShippingAddressMutation, { data, loading, error }] = useAddShippingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShippingAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddShippingAddressMutation, AddShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddShippingAddressMutation, AddShippingAddressMutationVariables>(AddShippingAddressDocument, options);
      }
export type AddShippingAddressMutationHookResult = ReturnType<typeof useAddShippingAddressMutation>;
export type AddShippingAddressMutationResult = ApolloReactCommon.MutationResult<AddShippingAddressMutation>;
export type AddShippingAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<AddShippingAddressMutation, AddShippingAddressMutationVariables>;