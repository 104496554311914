import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserDetailQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserDetailQuery = { __typename?: 'Query', userDetail: { __typename?: 'User', userId: string, name: string, email?: string | null, phoneNumber?: string | null, isAdmin: boolean } };


export const UserDetailDocument = gql`
    query userDetail {
  userDetail {
    userId
    name
    email
    phoneNumber
    isAdmin
  }
}
    `;
export type UserDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserDetailQuery, UserDetailQueryVariables>, 'query'>;

    export const UserDetailComponent = (props: UserDetailComponentProps) => (
      <ApolloReactComponents.Query<UserDetailQuery, UserDetailQueryVariables> query={UserDetailDocument} {...props} />
    );
    

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
      }
export function useUserDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
        }
export function useUserDetailSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
        }
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>;
export type UserDetailLazyQueryHookResult = ReturnType<typeof useUserDetailLazyQuery>;
export type UserDetailSuspenseQueryHookResult = ReturnType<typeof useUserDetailSuspenseQuery>;
export type UserDetailQueryResult = ApolloReactCommon.QueryResult<UserDetailQuery, UserDetailQueryVariables>;